import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-cool-inview";
import { tryGetFile } from "@sanity/asset-utils";
import lottie from "lottie-web/build/player/lottie_light";
import Picture from "./picture";

const sanityProjectDetails = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
};

const LottieOrImage = ({ lottieFile, image, width = 125, height = 25, jsonFile, className }) => {
  const anim = useRef(null);
  const animationContainer = useRef(null);
  const [animationData, setAnimationData] = useState(null);

  const { observe, inView } = useInView();

  useEffect(() => {
    if (lottieFile?.asset?._ref && animationContainer.current) {
      const file = tryGetFile(lottieFile, sanityProjectDetails);
      if (file?.asset?.url) {
        fetch(file.asset.url)
          .then((r) => r.json())
          .then((r) => r && setAnimationData(r))
          .catch((e) => console.log(e));
      }
    }
  }, [lottieFile?.asset?._ref, animationContainer.current]);

  useEffect(() => {
    if (animationData || jsonFile) {
      anim.current?.destroy();
      if (inView) {
        anim.current = lottie.loadAnimation({
          container: animationContainer.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: animationData || jsonFile,
        });
      }
    }
    return () => anim.current?.destroy();
  }, [inView, animationData]);

  return (
    <div ref={observe} className={className}>
      {(lottieFile || jsonFile) && <div style={{ height, width }} ref={animationContainer} />}
      {image && <Picture height={height} width={width} picture={{ image }} layout="fixed" objectFit="cover" />}
    </div>
  );
};

const LottieImage = (props) =>
  props.image || props.lottieFile || props.jsonFile ? <LottieOrImage {...props} /> : null;

LottieImage.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      _ref: PropTypes.string,
    }),
  }),
  lottieFile: PropTypes.shape({
    asset: PropTypes.shape({
      _ref: PropTypes.string,
    }),
  }),
};

LottieOrImage.propTypes = LottieImage.propTypes;

export default LottieImage;
